.inline{
    display:flex;
    flex-direction: row;
}
select{
   height: 25px;
}
.paddingzero{
    padding: 1%;
}
.margin-right{
    margin-right: 5px;
}
.outward-all{
    font-size: 14px;
    color: black;
}
.width{
    width: 100%;
}
.direct{
    margin-right: 5px;
}
.direct1{
    display: flex;
    align-content: center;
}
.radio-btn{
    margin-right: 5px;
}

.form{
    display: flex;
    justify-content: center;
    z-index: 0;
    }
    .form-section-1{
        border: 1px solid grey;
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        width: 80%;
        
      }
 /* section1 */
 .checkbox{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    
 }
 .checkbox1{
    display: flex;
    text-align: center;
    align-items: center;
    /* justify-content: space-evenly   ; */
    margin: .5px;

 }
 
 .margin-left{
    padding: 5px;
    border: .5px solid grey;

 }
 .margin-LR{
    margin-left: 2px;
    margin-right: 2px;
 }
 .batch{
    width: 60px;
 }
 .green-btn{
    background-color: green;
    color: #fff;
 }
 .section-1{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    align-items: center;
    
 }
 .Batch{
    width: 200px;
 }

 /* section 2 */
 .section-2{
    border: .5px solid grey;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
 }
 .form-section-2{
    justify-content: space-between;
 }
 .sec2-lebel{
    width: 100%;
 }
 /* .input1{
    width: 100%;
 } */

 /* section3 */
 .section-3{
    font-size: small;
 }
 .checkbox-sec3{
    display: flex;
    width: 30%;
 }
 .sec3-labels{
    width: 100%;
    display: flex;
    border-left: .5px solid grey; 
  }
  .sec3-labels1{
    /* border: .5px solid grey; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    width: 20%;
    padding: 10px;
  }
  .sec3-labels2{
    /* border: .5px solid grey; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    width: 20%;
    padding: 10px;
  }
  .sec3-labels3{
    /* border: .5px solid grey; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    width: 40%;
    padding: 10px;
  }
  .sec3-labels4{
    /* border: .5px solid grey; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    width: 20%;
    padding: 10px;
  }

  /* section5 */
  .sec4-labels1{
    display: flex;
    justify-content: space-between;
    
    padding: 5px;
  }
  .sec4-labels{
    text-align: center;
    margin-left: 18px;
  }
  .sec4-form{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  /* .section5-mid{
    border-left: .5px solid grey;
    border-right: .5px solid grey;
  } */
  .sec4-cols{
    width: 333%;
  }
  .sec4-radio{
    display: flex;
    flex-direction: start;
    align-items: center;
  }
  .sec4-radio1{
    margin-right: 10px;
  }